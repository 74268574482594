<template>
  <b-col xl="2" lg="3" md="4" sm="6" cols="6" class="user-card mb-1">
    <div class="image">
      <router-link :to="_route">
        <div class="img-overlay" @click="gtmUserDetail">
          <b-img-lazy fluid center blankColor="#ccc" class="user-image" :src="user.profile.image" />
        </div>
      </router-link>
      <div class="description">
        <div class="interests">
          <div v-if="user.interests">
            <b-badge v-for="(interest, index) in user.interests.slice(0, 2)" :key="index"
                     variant="dark">
              {{ interest.name }}
            </b-badge>
            <b-badge v-if="user.interests.length > 2" :id="'interests-tooltip-' + uuid" variant="dark">
              +{{ user.interests.length - 2 }}
            </b-badge>
            <b-tooltip :target="'interests-tooltip-' + uuid" triggers="hover">
              <p v-for="(interest, index) in user.interests.slice(2)" :key="index">
                {{ interest.name }}
              </p>
            </b-tooltip>
          </div>
        </div>
        <div style="z-index: 3">
          <router-link :to="_route">
            <div v-if="user.profile.country" class="country">
              <b-img-lazy :src="require(`@/assets/images/flags/${user.profile.country.toLowerCase()}.svg`)"
                          fluid rounded="circle" width="20"/>
              {{ $t(user.profile.countryName) }}
            </div>
            <div class="name" @click="gtmUserDetail">
              <status-indicator :status="user.profile.onlineStatus" />
              {{ user.userName }}<span v-if="user.profile.year">, {{ new Date().getFullYear() - user.profile.year }}</span>
            </div>
          </router-link>
          <div class="shortcuts">
<!--            <div class="heart" :class="{'is-fav': isFavorite}">-->
<!--              <b-img :src="iconHeartWhite" height="20" width="20"-->
<!--                     @click="isFavorite ? unlike() : like()"/>-->
<!--            </div>-->
            <router-link :to="_route" style="z-index: 3">
              <div class="chat" @click="gtmGoChat">
                <b-img :src="iconChatWhite" height="20" width="20"/>
              </div>
            </router-link>
            <div class="phone">
              <b-img :src="iconPhoneWhite" height="20" width="20" @click="call"/>
            </div>
            <div v-if="user.profile.cameraOn" class="video-call">
              <b-img :src="iconVideoCall" height="20" width="20" @click="videoCall"/>
            </div>
          </div>
        </div>
      </div>
    </div>
  </b-col>
</template>

<script>
import useJwt from '@/auth/jwt/useJwt'
import { BAspect, BBadge, BButton, BButtonGroup, BCol, BImg, BImgLazy, BRow, BSkeletonImg, BTooltip } from 'bootstrap-vue'
import { gtmTrackNewEvent, showNotify, throwDefaultError } from "@/helpers/helpers";
import StatusIndicator from '@/components/StatusIndicator'

export default {
  components: {
    BAspect,
    BBadge,
    BButton,
    BButtonGroup,
    BCol,
    BImg,
    BImgLazy,
    BRow,
    BSkeletonImg,
    BTooltip,

    StatusIndicator
  },
  data() {
    return {
      iconChatWhite: require('@/assets/images/icons/chat-white.svg'),
      iconHeartWhite: require('@/assets/images/icons/heart-white.svg'),
      iconHeartEmpty: require('@/assets/images/icons/heart-empty.svg'),
      iconPhoneWhite: require('@/assets/images/icons/phone-white.svg'),
      iconVideoCall: require('@/assets/images/icons/video-call.svg'),
      isFavorite: this.user.fav,
    }
  },
  props: {
    user: {
      type: Object,
      required: true
    },
    showLoginRoute: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    _route() {
      if (this.showLoginRoute) {
        return {
          name: 'login'
        }
      } else {
        return {
          name: 'users.show',
          params: {
            id: this.user.id
          }
        }
      }
    },
    gtmCategory() {
      switch (this.$route.name) {
        case 'discover':
          return 'Kesfet'
        case 'users.favorites':
          return 'Favorites'
        case 'users.search':
          return 'Search'
        default:
          return 'UserCard'
      }
    },
    uuid() {
      return Math.random() * 100000
    }
  },
  methods: {
    call() {
      this.$store.dispatch('app/setModalCallType', 'audio')
      this.$store.dispatch('app/setModalCall', false)
      setTimeout(() => {
        this.$store.dispatch('app/setModalCallUser', this.user)
        this.$store.dispatch('app/setModalCall', true)
      }, 33)
      // if (this.user?.profile?.callType == 2) {
      //   this.$store.dispatch('app/setModalDownloadMobileApp', true)
      // } else {
      //   if (!this.$store.getters['auth/userPhoneNumber']) {
      //     this.$store.dispatch('app/setModalAddPhoneNumberShowDiamond', false)
      //     this.$store.dispatch('app/setModalAddPhoneNumber', true)
      //     this.$store.dispatch('app/setVerificationType', 'phone')
      //   } else {
      //     this.$emit('setLoading', true)
      //     useJwt.callUser(this.user.id)
      //         .then(response => {
      //           showNotify(this.$toast, 'Arama başarılı', 'success')
      //
      //           gtmTrackNewEvent('call', {
      //             category: this.gtmCategory,
      //             action: 'Call',
      //             label: this.user.id
      //           })
      //         })
      //         .catch(error => {
      //           const { response } = error
      //           throwDefaultError(this.$toast, error)
      //           if (response && response.data?.header?.error?.errorCode == 215) {
      //             this.$store.dispatch('app/setModalAddPhoneNumberShowDiamond', false)
      //             this.$store.dispatch('app/setModalAddPhoneNumber', true)
      //             this.$store.dispatch('app/setVerificationType', 'phone')
      //           }
      //         })
      //         .finally(() => this.$emit('setLoading', false))
      //   }
      // }
    },
    gtmGoChat() {
      gtmTrackNewEvent('chatclick', {
        category: this.gtmCategory,
        action: 'ChatClick',
        label: this.user.id
      })
    },
    gtmUserDetail() {
      gtmTrackNewEvent('userdetail', {
        category: this.gtmCategory,
        action: 'UserDetail',
        label: this.user.id
      })
    },
    like() {
      this.$emit('setLoading', true)
      useJwt.addFavoriteUser(this.user.id)
          .then(response => {
            showNotify(this.$toast, this.$t('Liking successful'), 'success')
            this.isFavorite = true

            gtmTrackNewEvent('addfavorite', {
              category: this.gtmCategory,
              action: 'AddFavorite',
              label: this.user.id
            })
          })
          .catch(error => {
            throwDefaultError(this.$toast, error)
          })
          .finally(() => { this.$emit('setLoading', false) })
    },
    unlike() {
      this.$emit('setLoading', true)
      useJwt.removeFavoriteUser(this.user.id)
          .then(response => {
            showNotify(this.$toast, this.$t('Unliking successful'), 'success')
            this.isFavorite = false

            gtmTrackNewEvent('removefavorite', {
              category: this.gtmCategory,
              action: 'RemoveFavorite',
              label: this.user.id
            })
          })
          .catch(error => {
            throwDefaultError(this.$toast, error)
          })
          .finally(() => { this.$emit('setLoading', false) })
    },
    videoCall() {
      this.$store.dispatch('app/setModalCallType', 'video')
      this.$store.dispatch('app/setModalCall', false)
      setTimeout(() => {
        this.$store.dispatch('app/setModalCallUser', this.user)
        this.$store.dispatch('app/setModalCall', true)
      }, 33)
    },
  }
}
</script>

<style lang="scss" scoped>
.user-card {
  padding: 0 4px;
  .image {
    position: relative;
    overflow: hidden;
    width: 100%;
    .user-image {
      border-radius: 12px;
    }
  }
  .description {
    position: absolute;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    top: 0;
    left: 0;
    height: 100%;
    padding: 14px 12px 10px 12px;
    color: #fff;
    font-family: 'Lexend', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 22px;
    width: 100%;

    @media (max-width: 767px) {
      font-size: 14px;
      line-height: 17px;
    }
    .interests {
      z-index: 3;
      font-size: 12px;
      * {
        margin-right: 2px;
      }
    }
    .country {
      font-family: 'Lexend', sans-serif;
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      line-height: 22px;
      color: #FFFFFF;
      margin-bottom: 4px;
    }
    .name {
      display: flex;
      align-items: center;
      gap: 2px;
      z-index: 3 !important;
      font-family: 'Lexend', sans-serif;
      font-style: normal;
      font-weight: 600;
      font-size: 18px;
      line-height: 22px;
      color: #FFFFFF;
      margin-bottom: 8px;

      @media (max-width: 767px) {
        font-size: 14px;
        line-height: 17px;
        margin-bottom: 4px;
      }
    }
    .shortcuts {
      display: flex;
      justify-content: center;
      gap: 6px;
      div {
        z-index: 3 !important;
        border: 1px solid rgba(255, 255, 255, 0.5);
        border-radius: 50%;
        padding: 6px;
        cursor: pointer;
        @media (max-width: 767px) {
          padding: 6px;
          img {
            width: 16px;
            height: 16px;
          }
        }

        &.is-fav {
          background: #FF5A5F;
          border: 1px solid #FF5A5F;
        }
      }
    }
  }
}
</style>
